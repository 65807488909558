/* Global Styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root, .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Navigation */
nav {
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #007bff;
}

/* About */
.about {
  padding: 100px 20px;
}

.about h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.about p {
  font-size: 18px;
  line-height: 1.6;
}

/* Blog */
.blog {
  padding: 100px 20px;
}

.blog h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
}

.blog-post {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-post h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.blog-post p {
  font-size: 16px;
  color: #777;
}
